<template>
  <div class="termgenerator">
    <m-frame
      :routeList="menuList"
      alive-include="Home"
      title="降价通知"
      deletable
      deleteApi="/pricecutsremind-index-uninstall.html"
      @delete="deleteApp"
    />
  </div>
</template>

<script>
export default {
  setup() {
    const menuList = [
      {
        path: '',
        title: '操作指南',
      },
      {
        path: 'pricecutsremind-setting',
        title: '降价通知设置',
      },
      {
        path: 'mail-setting',
        title: '邮件设置',
      },
      {
        path: 'info-shopping-list',
        title: '通知商品列表',
      },
      {
        path: 'data-stat',
        title: '数据统计',
      },
    ];

    const deleteApp = async () => {};
    return {
      menuList,
      deleteApp,
    };
  },
};
</script>

<style lang="scss"></style>
